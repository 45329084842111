<template>
  <div id="prices">
    <Navbar />

    <div class="landing-page">
      <h1>Our Pricing</h1>
      <p>
        Our Service is Tailored to your usage with <br />
        affordable pricing plan
      </p>
    </div>
    <div class="price-section">
      <h2>Accessible Endpoints</h2>
      <div v-if="fullpage" class="anotherclass">
        <splide :options="options" aria-label="My Favorite Images">
          <splide-slide
            v-for="(price, index) in prices"
            :key="index"
            class="tw-flex"
          >
            <v-card elevation="1" color="white" class="tab-card">
              <v-card-title style="height: 150px">
                {{ price.title }}</v-card-title
              >
              <img src="../assets/price-img.png" alt="" />
              <v-card-text style="height: 150px">
                <div>
                  <v-icon class="pr-5 py-4">mdi-checkbox-marked-circle</v-icon>
                  {{ price.service1 }}
                </div>
                <div>
                  <v-icon class="pr-5 py-4">mdi-checkbox-marked-circle</v-icon
                  >{{ price.service2 }}
                </div>
                <div v-if="price.service3">
                  <v-icon class="pr-5 py-4">mdi-checkbox-marked-circle</v-icon>

                  {{ price.service3 }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="#004AAD" @click="showFullpage(index)">
                  View More
                </v-btn>
              </v-card-actions>
            </v-card>
          </splide-slide>
        </splide>
      </div>
      <div class="fullpage" v-else>
        <v-tabs
          background-color="#fff"
          centered
          next-icon="mdi-arrow-right"
          prev-icon="mdi-arrow-left"
          v-model="currentTab"
        >
          <v-tab class="options">Transport Company</v-tab>
          <v-tab class="options">Terminals </v-tab>
          <v-tab class="options">Routes</v-tab>
          <v-tab class="options">Trips </v-tab>
          <v-tab class="options">Bookings </v-tab>
          <v-tab-item style="">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Paid Services</th>
                    <th class="text-left">Prices(₦)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tcprices" :key="item.service">
                    <td>{{ item.service }}</td>
                    <td>{{ item.price }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Paid Services</th>
                    <th class="text-left">Prices(₦)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in terminalprices" :key="item.service">
                    <td>{{ item.service }}</td>
                    <td>{{ item.price }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Paid Services</th>
                    <th class="text-left">Prices(₦)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in routeprices" :key="item.service">
                    <td>{{ item.service }}</td>
                    <td>{{ item.price }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Paid Services</th>
                    <th class="text-left">Prices(₦)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tripsprices" :key="item.service">
                    <td>{{ item.service }}</td>
                    <td>{{ item.price }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Paid Services</th>
                    <th class="text-left">Prices(₦)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in bookingsprices" :key="item.service">
                    <td>{{ item.service }}</td>
                    <td>{{ item.price }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/reuseables/Navbar";
import Footer from "../views/Footer";
// import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";

export default {
  name: "pricing",
  components: { Navbar, Footer },
  data() {
    return {
      options: {
        rewind: true,
        gap: "1rem",
        autoplay: false,
        perPage: 4,
        type: "loop",
        arrows: true,
        pagination: false,
        breakpoints: {
          768: {
            perPage: 1,
            arrows: false,
          },
          1024: {
            perPage: 2,
            arrows: false,
          },
          1600: {
            perPage: 4,
            arrows: true,
          },
          1920: {
            perPage: 5,
            arrows: true,
          },
        },
      },
      tabs: "null",
      currentTab: "null",
      fullpage: true,
      destination: "",
      prices: [
        {
          title: "Transport Company",
          service1: "A Transport Company",
          service2: "All Transport Companies",
        },
        {
          title: "Terminals",
          service1: "A Terminal",
          service2: "All Terminals",
        },
        {
          title: "Routes",
          service1: "All Routes",
          service2: "Routes of a Terminal",
          service3: "A Route",
        },
        {
          title: "Trips",
          service1: "All Trips",
          service2: "Trips of a Terminal",
          service3: "A Trip",
        },
        {
          title: "Bookings",
          service1: "All Bookings",
          service2: "Bookings of a Terminal",
          service3: "Bookings of a Company",
        },
      ],
      tcprices: [
        {
          service: "View All Transport Companies",
          price: "3000",
        },
        {
          service: "View A Transport Company’s Terminal",
          price: "2000",
        },
      ],
      terminalprices: [
        {
          service: "View All Terminals",
          price: "3000",
        },
        {
          service: "View A Transport Company’s Terminal",
          price: "2000",
        },
      ],
      routeprices: [
        {
          service: "View All Routes",
          price: "3000",
        },
        {
          service: "View All Routes of a Terminal",
          price: "2000",
        },
        {
          service: "View A Route of A Transport Company",
          price: "2000",
        },
      ],
      tripsprices: [
        {
          service: "View all Ongoing Trips of a Transport Company",
          price: "3000",
        },
        {
          service:
            "View all Ongoing Trips of a Terminal of a  Transport Company",
          price: "2000",
        },
        {
          service:
            "View all Ongoing Trip Per Day of a Terminal of a Transport Company",
          price: "2000",
        },
        {
          service:
            "View all Ongoing Trip Per Date Range of a Transport Company",
          price: "2000",
        },
        {
          service:
            "View all Completed Trip Per Date Range of a Transport Company",
          price: "2000",
        },
        {
          service: "View all Completed Trip Per Month of a Transport Company",
          price: "3000",
        },
        {
          service:
            "View all Cancelled Trip Per Date Range of a Terminal of a Transport Company",
          price: "3000",
        },
      ],
      bookingsprices: [
        {
          service: "Make Bookings",
          price: "3000",
        },
        {
          service: "View All Bookings of a Transport Company",
          price: "2000",
        },
        {
          service: "View All Bookings of a Terminal",
          price: "2000",
        },
        {
          service: "View Total Bookings Per Day of a Terminal",
          price: "2000",
        },
        {
          service: "View All Bookings Per Day of a Transport Company",
          price: "2000",
        },
        {
          service: "View A Trip of a Terminal",
          price: "3000",
        },
        {
          service: "View All Trips",
          price: "3000",
        },
        {
          service: "View All Ongoing Trips of a Terminal",
          price: "3000",
        },
        {
          service: "View A Trip of a Terminal",
          price: "3000",
        },
        {
          service: "View A Trip of a Terminal",
          price: "3000",
        },
        {
          service: "View All Trips",
          price: "3000",
        },
        {
          service: "View All Ongoing Trips of a Terminal",
          price: "3000",
        },
        {
          service: "View A Trip of a Terminal",
          price: "3000",
        },
      ],
    };
  },
  methods: {
    showFullpage(index) {
      this.currentTab = index;
      this.fullpage = !this.fullpage;
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-page {
  min-height: 100vh;
  background-image: url(../../src/assets/pricebg.png);
  background-size: cover;
  background-position: center center;
  color: #fff;

  text-align: center;
  width: 100%;
  padding: 400px 0 300px;
  @media screen and (max-width: 768px) {
    background-size: cover;
    background-image: url("../assets/pricemobile.png");
    background-position: center;
  }
  @media (max-width: 500px) {
    padding: 400px 20px 300px;
  }
  h1 {
    font-family: "Raleway";
    font-weight: 600;
    font-size: 45px;
    padding-bottom: 20px;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
  }
}
.price-section {
  min-height: 100vh;
  background-color: #fdfffc;
  padding: 100px 10rem;
  @media (max-width: 1400px) {
    padding: 100px 5rem;
  }
  @media (max-width: 768px) {
    padding: 100px 10%;
  }
  @media (max-width: 500px) {
    padding: 100px 15px;
  }
  h2 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 54px;
    /* identical to box height, or 150% */

    text-align: center;

    color: #132534;
  }
}
.v-tab {
  text-transform: initial;
}
.v-card__title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  color: #1d262d;
  padding: 50px 0;
  word-break: initial;
}
.v-card__text {
  padding: 20px 0 !important;
}
.tab-card {
  width: 300px;
  height: auto;
  position: relative;
  padding: 30px;
  margin-right: 50px;
  img {
    position: absolute;
    width: 130px;
    top: 5px;
    left: 0;
  }
  @media (max-width: 800px) {
    margin: 30px 0;
    width: 100%;
  }
}
.v-btn {
  color: white;
  margin: 60px 0 20px;
  width: 100%;
  padding: 25px 80px !important;
}
.scroll {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 50px;
  background-color: #fdfffc;
  @media (max-width: 800px) {
    overflow: hidden;
    display: block !important;
    margin: 0 auto;
    padding: 0;
  }
}
.scroll::-webkit-scrollbar {
  display: none;
}
.fullpage {
  background-color: #fff;
  min-height: 80vh;
  padding: 100px 10rem;
  @media (max-width: 1264px) {
    padding: 100px 5rem;
  }
  @media (max-width: 850px) {
    padding: 100px 0;
  }

  td {
    padding: 0 40px !important;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 42px;
    color: #525456;
  }
  th.text-left {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 42px;
    color: #1d262d;
    padding: 0 40px !important;
  }
  .space {
    margin-top: 100px;
  }
  .v-data-table {
    width: 100%;
    @media (max-width: 800px) {
      width: 100%;
    }
  }

  ::v-deep
    .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
    > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
    .v-slide-group__prev {
    @media screen and (max-width: 768px) {
      //display: none !important;
    }
  }

  .options {
    padding: 0 50px;
    @media (max-width: 800px) {
      padding: 0 20px !important;
    }
  }
  th,
  td {
    border: thin solid rgb(0 0 0 / 0.05) !important;
  }
}
.anotherclass {
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

::v-deep .splide__arrow--next {
  right: -3rem;
}
::v-deep .splide__arrow--prev {
  left: -3rem;
}
</style>